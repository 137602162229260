import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    activeUser: "",
    banners: {},
    testimonials: [],
    coreValues: [],
    gallaries: [],
    location: [],
    awards: [],
  },
  plugins: [createPersistedState()],
  mutations: {
    UPDATE_USER(state, payload) {
      state.activeUser = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_BANNERS(state, banners) {
      state.banners = banners;
    },
    SET_TESTIMONIALS(state, testimonials) {
      state.testimonials = testimonials;
    },
    SET_COREVALUES(state, coreValues) {
      state.coreValues = coreValues;
    },
    SET_GALLARY(state, gallaries) {
      state.gallaries = gallaries;
    },
    SET_LOCATION(state, location) {
      state.location = location;
    },
    SET_AWARDS(state, awards) {
      state.awards = awards;
    },
  },
  actions: {
    fetchActiveUser(context) {
      axios
        .get("/candidate/", {
          headers: {
            Authorization: `Bareer ${localStorage.getItem("_token")}`,
          },
        })
        .then((res) => {
          context.commit("UPDATE_USER", res.data);
        })
        .catch((err) => {
          context.commit("UPDATE_USER", "");
        });
    },
    // New Action for fetching banners
    fetchBanners(context) {
      axios
        .get("website/website-title/", {
          headers: { Authorization: `Bearer ${context.state.token}` },
        })
        .then((res) => {
          context.commit("SET_BANNERS", res.data); // Commit the data to the state
          //   console.log("Banners Data:", res.data);
        })
        .catch((err) => {
          console.error("Failed to fetch banners:", err);
        });
    },
    fetchCoreValues(context) {
      axios
        .get("website/benefits/", {
          headers: { Authorization: `Bearer ${context.state.token}` },
        })
        .then((res) => {
          // console.log("Outdoor Data Full Response:", res);
          context.commit("SET_COREVALUES", res.data);
        })
        .catch((err) => {
          console.error("Failed to fetch outdoor data:", err);
        });
    },

    // New Action for fetching banners
    fetchEmployeeTestimonial(context) {
      axios
        .get("/website/employee-testimonial/", {
          headers: { Authorization: `Bearer ${context.state.token}` },
        })
        .then((res) => {
          context.commit("SET_TESTIMONIALS", res.data); // Commit the data to the state
          // console.log("Testimonial data ....................... :", res);
        })
        .catch((err) => {
          console.error("Failed to fetch banners:", err);
        });
    },
    // New Action for fetching banners
    fetchOurGallary({ commit, state }) {
      axios
        .get("/website/life-at-mediusware/", {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          commit("SET_GALLARY", res.data.results); // Commit the gallery results to the mutation
          console.log("Gallery data fetched:", res.data.results);
        })
        .catch((err) => {
          // console.error("Failed to fetch gallery images:", err);
        });
    },
    fetchOurLocation({ commit, state }) {
      axios
        .get("/website/office-location/", {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          commit("SET_LOCATION", res); // Commit the gallery results to the mutation
          console.log("Location data fetched:", res);
        })
        .catch((err) => {
          console.error("Failed to fetch LOCATION :", err);
        });
    },
    fetchAwards({ commit, state }) {
      axios
        .get("/website/awards/", {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          commit("SET_AWARDS", res.data.results); // Commit the gallery results to the mutation
          // console.log("Gallery data fetched:", res.data);
        })
        .catch((err) => {
          console.error("Failed to fetch awards :", err);
        });
    },

    clearState(context) {
      context.commit("SET_TOKEN", "");
      context.commit("UPDATE_USER", "");
    },
  },
});
