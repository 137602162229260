<template>
  <div class="slider-container">
    <div
      class="slides"
      :style="{ transform: `translateX(-${currentSlide * (100 / 3)}%)` }"
    >
      <div v-for="(item, index) in visibleSlides" :key="index" class="slide">
        <p>Slide {{ index + 1 }}</p>
      </div>
    </div>
    <div class="slider-buttons">
      <button @click="prevSlide">Previous</button>
      <button @click="nextSlide">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleSlider",
  data() {
    return {
      currentSlide: 0,
      slides: [
        { id: 1, text: "Slide 1" },
        { id: 2, text: "Slide 2" },
        { id: 3, text: "Slide 3" },
        { id: 4, text: "Slide 4" },
        { id: 5, text: "Slide 5" },
        { id: 6, text: "Slide 6" },
      ],
    };
  },
  computed: {
    visibleSlides() {
      // Infinite loop: always show the next 3 slides
      const slideCount = this.slides.length;
      return [
        this.slides[(this.currentSlide + 0) % slideCount],
        this.slides[(this.currentSlide + 1) % slideCount],
        this.slides[(this.currentSlide + 2) % slideCount],
      ];
    },
  },
  methods: {
    nextSlide() {
      const slideCount = this.slides.length;
      this.currentSlide = (this.currentSlide + 1) % slideCount;
    },
    prevSlide() {
      const slideCount = this.slides.length;
      this.currentSlide = (this.currentSlide - 1 + slideCount) % slideCount; 
    },
  },
};
</script>

<style scoped>
.slider-container {
  position: relative;
  overflow: hidden;
  width: 100%; /* Full width */
  padding-bottom: 60px; /* Adjust padding to make room for buttons */
}

.slides {
  display: flex;
  transition: transform 0.5s ease; /* Smooth transition between slides */
}

.slide {
  min-width: calc(100% / 3); /* Show 3 slides at a time */
  box-sizing: border-box;
  padding: 20px;
  background: #f3f3f3; /* Background color for each slide */
  border: 1px solid #ccc; /* Border for each slide */
  text-align: center;
}

.slider-buttons {
  display: flex; /* Use flexbox for buttons alignment */
  justify-content: center; /* Center buttons horizontally */
  position: absolute; /* Position buttons relative to the container */
  left: 50%; /* Center the buttons */
  transform: translateX(-50%); /* Adjust for centering */
  bottom: 10px; /* Position buttons above the bottom edge */
}
</style>
