<template>
  <div class="careerSection" id="careerSection">
    <div class="container">
      <div class="careerHeader">
        <h2 class="title">
          Career <span class="titleSpanColor">Openings</span>
        </h2>
        <p class="description">
          We’re always looking for creative, talented self-starters to join the
          <a href="https://mediusware.com/">MEDIUSWARE</a> family. Check out our
          open roles below and fill out an application.
        </p>
      </div>

      <!-- filter for desktop  -->
      <div class="mb-5 d-none">
        <div class="d-flex gap-5 careerFilter">
          <div
              :class="{
              active: filter === 'ALL CATEGORY',
            }"
              class="careerFilterItem"
              @click="this.selectFilter"
          >
            ALL CATEGORY
          </div>
          <div
              :class="{
              active: filter === 'HT & ADMIN',
            }"
              class="careerFilterItem"
              @click="this.selectFilter"
          >
            HT & ADMIN
          </div>
          <div
              :class="{
              active: filter === 'ENGINEERING',
            }"
              class="careerFilterItem"
              @click="this.selectFilter"
          >
            ENGINEERING
          </div>
          <div
              :class="{
              active: filter === 'SUPPORT',
            }"
              class="careerFilterItem"
              @click="this.selectFilter"
          >
            SUPPORT
          </div>
          <div
              :class="{
              active: filter === 'DESIGN',
            }"
              class="careerFilterItem"
              @click="this.selectFilter"
          >
            DESIGN
          </div>
          <div
              :class="{
              active: filter === 'DIGITAL MARKETING',
            }"
              class="careerFilterItem"
              @click="this.selectFilter"
          >
            DIGITAL MARKETING
          </div>
        </div>
      </div>

      <!-- filter for mobile -->
      <div class="mb-5 careerFilterSmall">
        <p>Choose Department</p>
        <select
            class="form-select form-select-lg mb-3"
            aria-label="Large select example"
        >
          <option selected>All Category</option>

          <option @click="this.selectFilter">HT & ADMIN</option>
        </select>
      </div>

      <div class="row g-4">
        <div
            class="col-sm-12 col-md-6 col-lg-4"
            v-for="(job, index) in jobs.results"
            :key="index"
        >
          <div class="jobContainer border">
            <h2 class="title">{{ job.title }}</h2>
            <div class="gap-2 mt-3 mb-4">
              <button class="buttonLeft">
                <img
                    :src="'../../../assets/applicationProcess/exprerience.svg'"
                    alt=""
                />
                {{ job.job_summery ? job.job_summery.experience : "" }}
              </button>
              <button class="buttonRight mt-3">
                <img
                    :src="'../../../assets/applicationProcess/salary.svg'"
                    alt=""
                />
                {{ job.job_summery ? job.job_summery.salary_range : "" }}
              </button>
            </div>
            <div class="d-flex justify-content-between">
              <button class="calendarButton">
                <img
                    :src="'../../../assets/applicationProcess/calendar.svg'"
                    alt=""
                />
                <!--                {{ job.updated_at }}-->
                {{ job.job_summery.application_deadline }}
              </button>
              <router-link :to="'job/' + job.slug">
                <button class="detailsButton position-relative">
                  <img
                      :src="'../../../assets/applicationProcess/arrow.svg'"
                      class="arrowWhite"
                      alt=""
                  />
                  Details
                  <img
                      :src="'../../../assets/applicationProcess/arrowgreen.svg'"
                      class="arrowBlue"
                      alt=""
                  />
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import SectionHeader from "./SectionHeader.vue";

export default {
  components: {SectionHeader},
  data      : () => ({
    jobs    : [],
    tempJobs: [],
    filter  : "ALL CATEGORY",
  }),
  methods   : {
    searchJobs(job) {
      let jobs  = job;
      this.jobs = [];
      this.jobs.push(jobs);
    },
    allJobs() {
      this.jobs = this.tempJobs;
    },

    selectFilter(e) {
      this.filter = e.target.innerText;
    },
  },
  mounted() {
    this.tempJobs = this.jobs;
    // console.log("hello ", this.jobs)
  },
  beforeMount() {
    axios.get("/jobs").then((res) => {
      this.jobs = res.data;
    });
  },

  updated() {
    console.log(
        "Component has been updated. Current selectedText:",
        this.filter
    );
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width : 991px) {
  .careerHeader {
    padding : 30px 0px 30px !important;

    .title {
      font-size : 30px !important;
    }
  }
}

.careerSection::after {
  position         : absolute;
  content          : "";
  background-image : linear-gradient(100deg, #0072bc 1.06%, #009fa9 100.89%);
  filter           : blur(400px);
  width            : 100%;
  height           : 102px;
  top              : 50%;
}

.careerSection {
  position : relative;

  .careerFilter {
    @media screen and (max-width : 991px) {
      display : none !important;
    }

    .careerFilterItem {
      cursor : pointer;

      // hover
      &:hover {
        color : #00a88e;
      }

      // active
      &.active {
        border-bottom : 1px solid #00a88e;
        color         : #00a88e;
      }
    }
  }

  .careerFilterSmall {
    display : block;
    @media screen and (min-width : 991px) {
      display : none !important;
    }
  }

  .container {
    position : relative;
    z-index  : 100;

    .careerHeader {
      width      : 100%;
      max-width  : 895px;
      text-align : center;
      padding    : 120px 0px 60px;
      margin     : 0 auto;

      .title {
        color          : #333;
        text-align     : center;
        font-size      : 40px;
        font-style     : normal;
        font-weight    : 700;
        line-height    : 50px;
        letter-spacing : -0.8px;

        span {
          color : #00a88e;
        }
      }

      .description {
        color       : #4f4f4f;
        text-align  : center;
        font-size   : 18px;
        font-style  : normal;
        font-weight : 400;
        line-height : 26px;
        margin-top  : 24px;

        @media screen and (max-width : 1199px) {
          margin-top : 16px;
        }

        a {
          cursor : pointer;
          color  : #00a88e;
        }
      }
    }

    .jobContainer {
      border-radius   : 12px;
      padding         : 28px;
      background      : #fdfdfd;
      border-left     : 4px solid #fdfdfd;
      border-bottom   : 4px solid #fdfdfd;
      height          : 100%;
      min-height      : 260px;
      display         : flex;
      flex-direction  : column;
      justify-content : space-between;
      transition      : all 0.4s;

      &:hover {
        border-left   : 4px solid #00a88e;
        border-bottom : 4px solid #00a88e;
      }

      .title {
        color       : #333;
        font-size   : 24px;
        font-style  : normal;
        font-weight : 600;
        line-height : normal;
      }

      .buttonLeft {
        border-radius   : 8px;
        background      : #e6f6f4;
        color           : #4f4f4f;
        font-size       : 16px;
        font-style      : normal;
        font-weight     : 600;
        line-height     : 30px;
        border          : none;
        display         : flex;
        align-items     : center;
        justify-content : start;
        text-align      : start;
        gap             : 5px;
        padding         : 11px 16px;
        width           : 100%;
      }

      .buttonRight {
        border-radius   : 12px;
        background      : #e6f6f4;
        color           : #4f4f4f;
        font-size       : 16px;
        font-style      : normal;
        font-weight     : 600;
        line-height     : 30px;
        border          : none;
        display         : flex;
        align-items     : center;
        justify-content : start;
        text-align      : left;
        gap             : 5px;
        padding         : 11px 16px;
        width           : 100%;
      }

      .calendarButton {
        color       : #4f4f4f;
        font-size   : 16px;
        font-style  : normal;
        font-weight : 600;
        line-height : 30px;
        background  : none;
        border      : none;
        display     : flex;
        align-items : center;
        gap         : 5px;
      }

      .detailsButton {
        color         : #00a88e;
        font-size     : 20px;
        font-style    : normal;
        font-weight   : 600;
        line-height   : 30px;
        border        : none;
        background    : none;
        display       : flex;
        align-items   : center;
        gap           : 5px;
        padding       : 4px 8px;
        border-radius : 8px;
        overflow      : hidden;
        padding-right : 40px !important;
        transition    : all 0.4s;

        .arrowWhite {
          position : absolute;
          left     : -40px;
        }

        .arrowBlue {
          position : absolute;
          left     : 71%;
        }

        &:hover {
          padding       : 4px 8px;
          border-radius : 8px;
          background    : #0060af;
          color         : #eef6fd;

          .arrowBlue {
            left : 100%;
          }

          .arrowWhite {
            left : 71%;
          }
        }
      }
    }
  }
}
</style>
