<template>
  <div class="jobContainer">
    <OpenPositionTemplate />
    <!-- <SimpleComponent /> -->
    <HomeSliderVideo />

    <div class="container">
      <SectionHeader
        titleLeft="Application"
        titleRight="Process"
        description="Our application process is designed to find talent for our dynamic IT environment. Begin with an online application to showcase your qualifications"
      />
      <div>
        <div class="card-group d-none d-xl-block">
          <div class="row">
            <div class="application-process-bg">
              <lottie-vue-player
                :src="JSON.stringify(lottie_json)"
                :autoplay="true"
                :loop="true"
                :speed="0.4"
              >
              </lottie-vue-player>
            </div>
            <div
              class="col"
              v-for="(values, index) in newProcessList"
              :key="index"
            >
              <ApplicationProcessCard
                :imageid="index + 1"
                :title_pro="values.title"
                :description_pro="values.description"
              />
            </div>
          </div>
        </div>

        <div class="d-block d-xl-none">
          <div class="row g-3">
            <div
              class="col-sm-12"
              v-for="(values, index) in newProcessList"
              :key="index"
            >
              <ApplicationProcessCard
                :imageid="index + 1"
                :title_pro="values.title"
                :description_pro="values.description"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="career">
      <CareerOptionsContainer />
    </div>
    <!-- 
    <div class="container ourCoreValuesContainer">
      <SectionHeader
        titleLeft="Our Core "
        titleRight="Values"
        description="Our core values are the guiding principles that underpin our work and define our culture. Integrity is at the heart of everything we do; we believe in honesty, transparency, and ethical behavior."
      />

      <div>
        <div class="row gx-3">
          <div
            class="col-sm-3 d-none d-xl-block"
            v-for="(values, index) in coreValues"
            :key="index"
          >
            <div :class="[(index + 1) % 2 === 0 && 'cardStyle']">
              <CoreValueCard
                :borderColor="values.borderColor"
                :title="values.title"
                :description="values.description"
              />
            </div>
          </div>
        </div>
        <div class="row g-3">
          <div
            class="col-6 col-md-6 d-block d-xl-none"
            v-for="(values, index) in coreValues"
            :key="index"
          >
            <CoreValueCard
              :borderColor="values.borderColor"
              :title="values.title"
              :description="values.description"
            />
          </div>
        </div>
      </div>
    </div> -->
    <div class="w-100 mt-5" style="background-color: #eff6fa !important">
      <div class="container ourCoreValuesContainer pb-5">
        <SectionHeader
          titleLeft="Our Core "
          titleRight="Values"
          description="Our core values are the guiding principles that underpin our work and define our culture. Integrity is at the heart of everything we do; we believe in honesty, transparency, and ethical behavior."
        />

        <div>
          <div class="row gx-3">
            <div
              class="col-sm-3 mb-3"
              v-for="(item, index) in coreValues"
              :key="index"
            >
              <div class="d-flex p-4 gap-2 align-items-center core-card">
                <div>
                  <img
                    :src="item.icon"
                    alt=""
                    class="core-card-icon object-fit-contain image-process"
                  />
                </div>
                <div></div>
                <div>
                  <h6 class="fs-4">{{ item.title }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lifeAtMWContainer">
      <div class="pb-5">
        <div class="container">
          <SectionHeader
            titleLeft="Life At"
            titleRight="Mediusware"
            description="Our employees thrives on solving complex challenges, embracing new technologies, and pushing the boundaries of what's possible in the software world."
          />
        </div>
        <!-- dynamicColor="#757E95" -->

        <!-- <div class="row">
          <div class="col-12 cs-grid">
            <div
              class="mb-3 mb-sm-4 mb-lg-5"
              v-for="(values, index) in 6"
              :key="index"
            >
              <img
                :src="
                  '../../../assets/lifeAtMediusware/compressed-lifeat' +
                  (index + 1) +
                  '.jpg'
                "
                alt=""
                class="w-100"
              />
            </div>
          </div>
        </div> -->

        <div v-if="gallaries.length" class="position-relative">
          <!-- Pass settings to VueSlickCarousel -->
          <VueSlickCarousel
            :dots="slickSettings.dots"
            :infinite="slickSettings.infinite"
            :autoplay="slickSettings.autoplay"
            :autoplaySpeed="slickSettings.autoplaySpeed"
            :slidesToShow="slickSettings.slidesToShow"
            :slidesToScroll="slickSettings.slidesToScroll"
            :arrows="slickSettings.arrows"
            :responsive="slickSettings.responsive"
            :speed="slickSettings.speed"
            :cssEase="slickSettings.cssEase"
          >
            <div
              v-for="(item, index) in gallaries"
              :key="index"
              class="px-2 position-relative slide"
            >
              <div class="card shadow-sm px-3 rounded cursor-pointer">
                <div
                  class="card-img-top object-fit-contain rounded-2"
                  :style="{
                    backgroundImage: `url(${item})`,
                    height: '350px',
                    width: '100%',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }"
                  @click="openModal(item.url)"
                ></div>
              </div>
            </div>
          </VueSlickCarousel>
          <div class="d-flex justify-content-center mt-2">
            <div class="d-flex gap-1">
              <button
                class="carousel-button border-0 bg-transparent"
                @click="showPrev"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                  <path
                    d="M177.5 414c-8.8 3.8-19 2-26-4.6l-144-136C2.7 268.9 0 262.6 0 256s2.7-12.9 7.5-17.4l144-136c7-6.6 17.2-8.4 26-4.6s14.5 12.5 14.5 22l0 72 288 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-288 0 0 72c0 9.6-5.7 18.2-14.5 22z"
                  />
                </svg>
              </button>
              <button
                class="carousel-button border-0 bg-transparent"
                @click="showNext"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                  <path
                    d="M334.5 414c8.8 3.8 19 2 26-4.6l144-136c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22l0 72L32 192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l288 0 0 72c0 9.6 5.7 18.2 14.5 22z"
                  />
                </svg>
              </button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LottieAnimation from "lottie-web-vue"
import Animationlayer from "./Animationlayer2.json";
import SectionHeader from "../../../components/SectionHeader.vue";
import CoreValueCard from "../../../components/CoreValueCard.vue";
import OpenPositionTemplate from "../../../components/OpenPositionTemplate.vue";
import ApplicationProcessCard from "../../../components/ApplicationProcessCard.vue";
import CareerOptionsContainer from "../../../components/CareerOptionsContainer.vue";
import SimpleComponent from "../../../components/JobsHomeSliderOne.vue";
import HomeSliderVideo from "../../../components/HomeSlickVideoSlider.vue";
import envelopeIcon from "../../../../public/assets/fitness.svg";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "NewJob",
  components: {
    OpenPositionTemplate,
    SectionHeader,
    CoreValueCard,
    ApplicationProcessCard,
    CareerOptionsContainer,
    SimpleComponent,
    HomeSliderVideo,
    VueSlickCarousel,
  },

  data() {
    return {
      lottie_json: Animationlayer,
      newProcessList: [
        {
          title: "Application review",
          description:
            "We evaluate applications using multiple criteria to find the best candidates.",
        },
        {
          title: "Quick test",
          description:
            "We conduct online interviews to get to know you better.",
        },
        {
          title: "Exam",
          description:
            "Finalists participate in a one-day onsite challenge for the final judge.",
        },
        {
          title: "Interviews",
          description:
            "Team leads are conducting this interview to recruit new hires.",
        },
        {
          title: "Offer",
          description:
            "After a successful interview, you'll get a job offer at Mediusware.",
        },
      ],
      slickSettings: {
        dots: false,
        infinite: true,
        speed: 3000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 556,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },

      slideList: [
        {
          img: "https://picsum.photos/800/300/?image=1",
        },

        {
          img: "https://picsum.photos/800/300/?image=1",
        },
      ],
    };
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
  },
  computed: {
    coreValues() {
      return this.$store.state.coreValues;
    },
    gallaries() {
      return this.$store.state.gallaries;
    },
  },
  created() {
    this.$store.dispatch("fetchCoreValues");
  },
  created() {
    this.$store.dispatch("fetchOurGallary");
  },
};
</script>

<style scoped lang="scss">
.jobContainer {
  .appProcessContainer {
    .card-group {
      position: relative;

      .application-process-bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
      }
    }
  }

  .ourCoreValuesContainer {
    .cardStyle {
      margin-top: 24px;
    }
  }

  .cs-grid {
    display: grid;
    grid-template-columns: 1fr 493px 1fr;
  }

  @media (max-width: 1399px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 424px 1fr;
    }
  }

  @media (max-width: 1199px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 356px 1fr;
    }
  }

  @media (max-width: 991px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 265px 1fr;
    }
  }

  @media (max-width: 768px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 197px 1fr;
    }
  }

  @media (max-width: 575px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 163px 1fr;
    }
    .core-card {
      min-height: 100px;
    }
  }

  @media (max-width: 399px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 136px 1fr;
    }
  }
}
.item-icon {
  width: 100px;
  height: 100px;
}
.images-process {
  height: 60px;
  width: 60px;
  margin-right: 1rem;
}
.core-card {
  background: #fdfdfd !important;

  border-radius: 12px;
  cursor: pointer;
  min-height: 130px;
}
.core-card-icon {
  width: 30px;
  height: 30px;
}
.carousel-button {
  width: 35px;
  cursor: pointer;
  padding: 5px;
}
.carousel-button svg {
  fill: #002b4f;
}
</style>
