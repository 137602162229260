<template>
  <div>
    <Nav ref="landingNav" />
    <main>
      <div class="main-content">
        <router-view />
      </div><!-- /.container -->
      <Footer ref="footer" />
    </main>
  </div>
</template>

<script>
import Nav from './inc/Nav'
import Footer from './inc/Footer'

export default {
  name: "Layout",
  components: { Nav, Footer }
}
</script>

<style scoped></style>