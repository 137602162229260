<template>
  <div class="herosection">
    <div class="wrapper">
      <div class="container-fluid">
        <div class="row">
          <!-- left -->
          <div class="col-lg-12 px-0 d-flex flex-column align-items-center">
            <div>
              <span
                class="badge rounded-pill text-bg-light bg-white herosection__jobs d-inline-flex"
              >
                <svg
                  class="me-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_888_15533)">
                    <path
                      d="M12.6667 2.66667H11.9333C11.7786 1.91428 11.3692 1.23823 10.7742 0.752479C10.1791 0.266727 9.4348 0.000969683 8.66667 0L7.33333 0C6.5652 0.000969683 5.82088 0.266727 5.22583 0.752479C4.63079 1.23823 4.2214 1.91428 4.06667 2.66667H3.33333C2.4496 2.66773 1.60237 3.01925 0.97748 3.64415C0.352588 4.26904 0.00105857 5.11627 0 6L0 8H16V6C15.9989 5.11627 15.6474 4.26904 15.0225 3.64415C14.3976 3.01925 13.5504 2.66773 12.6667 2.66667ZM5.456 2.66667C5.59339 2.27806 5.84749 1.94139 6.18353 1.70273C6.51958 1.46406 6.92116 1.33504 7.33333 1.33333H8.66667C9.07884 1.33504 9.48042 1.46406 9.81647 1.70273C10.1525 1.94139 10.4066 2.27806 10.544 2.66667H5.456Z"
                      fill="#0060AF"
                    />
                    <path
                      d="M8.66667 9.9987C8.66667 10.1755 8.59643 10.3451 8.47141 10.4701C8.34638 10.5951 8.17681 10.6654 8 10.6654C7.82319 10.6654 7.65362 10.5951 7.5286 10.4701C7.40357 10.3451 7.33333 10.1755 7.33333 9.9987V9.33203H0V12.6654C0.00105857 13.5491 0.352588 14.3963 0.97748 15.0212C1.60237 15.6461 2.4496 15.9976 3.33333 15.9987H12.6667C13.5504 15.9976 14.3976 15.6461 15.0225 15.0212C15.6474 14.3963 15.9989 13.5491 16 12.6654V9.33203H8.66667V9.9987Z"
                      fill="#0060AF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_888_15533">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Jobs</span
              >
            </div>

            <h2
              class="herosection__title flex justify-content-center text-center text-white"
            >
              Empower
              <span class="text-highlight ">Your Career</span> <br />
              Journey with Us
            </h2>

            <p class="herosection__queries text-white">For Any Queries Email:</p>
            <p class="herosection__email text-white">career@mediusware.com</p>

            <div class="">
              <div>
                <button
                  class="hire-developer-black-btn d-flex align-items-center book-a-call position-relative"
                  @click="scrollToCareerSection"
                >
                  <div class="text-white">
                    <svg
                      width="36"
                      height="16"
                      viewBox="0 0 36 16"
                      fill="#ffffff"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35.7071 8.70711C36.0976 8.31659 36.0976 7.68342 35.7071 7.2929L29.3431 0.928935C28.9526 0.53841 28.3195 0.53841 27.9289 0.928935C27.5384 1.31946 27.5384 1.95262 27.9289 2.34315L33.5858 8L27.9289 13.6569C27.5384 14.0474 27.5384 14.6805 27.9289 15.0711C28.3195 15.4616 28.9526 15.4616 29.3431 15.0711L35.7071 8.70711ZM-8.74228e-08 9L35 9L35 7L8.74228e-08 7L-8.74228e-08 9Z"
                        fill="#ffffff"
                      />
                    </svg>
                  </div>
                  <div class="d-flex align-items-center my-auto">
                    <h6 class="text-title ms-2 mt-1 text-white">Open Positions</h6>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <!-- right -->
          <!-- <div class="col-lg-6 px-0">
            <div class="relative">
              <lottie-vue-player
                id="herosection-lottie"
                :src="JSON.stringify(lottie_json)"
                :autoplay="true"
                :loop="true"
              >
              </lottie-vue-player>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <!-- <img
      id="herosection__overlay_first"
      src="/assets/overlay-1.svg"
      alt=""
      height="{500}"
      width="{1280}"
    /> -->
    <!-- <img
      id="herosection__overlay_second"
      className="absolute top-0 right-0 z-[-1] herosection__overlay"
      src="/assets/overlay-2.svg"
      alt=""
      height="{200}"
      width="{580}"
    /> -->

    <img
      id="herosection__overlay_second"
      className="absolute top-0 bottom-0 right-0 z-[-1] herosection__overlay"
      src="/assets/Career.jpg"
      alt=""
      height="{200}"
      width="{580}"
    />
  </div>
</template>

<script>
import Animationlayer from "../../public/lottiefiles/herosection.json";

export default {
  data: () => ({
    lottie_json: Animationlayer,
  }),
  methods: {
    scrollToCareerSection() {
      document
        .getElementById("careerSection")
        .scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped lang="scss">
.text-highlight {
  color: #00a88e;
}

.herosection {
  background: #e6eff7 !important;
  position: relative;
  overflow: hidden;
  z-index: 1;

  #herosection-lottie {
    // background: #e6eff7 !important;
  }

  .herosection__jobs {
    background: #00a88e;
    color: #0060af !important;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 16px;
    margin-bottom: 16px;
    font-weight: 500;
    p {
      font-size: 14px;
    }
  }

  .herosection__title {
    // font-weight: 900;
    margin-bottom: 32px;
    color: #002b4f;
    font-size: 52px;
    line-height: 70px;
    letter-spacing: -1px;

    @media screen and (max-width: "991px") {
      font-size: 32px;
      line-height: 44px;
    }
  }

  .herosection__queries {
    font-size: 18px;
    font-weight: lighter;
    line-height: 19px;
    margin-bottom: 0.5rem;
    color: #344054;

    @media screen and (max-width: "991px") {
      font-size: 16px;
    }
  }
  .herosection__email {
    font-size: 32px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 48px;
    color: #344054;

    @media screen and (max-width: "991px") {
      font-size: 16px;
      font-weight: 700;
    }
  }

  #herosection__overlay_first {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.05;
  }

  #herosection__overlay_second {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.book-a-call {
  padding: 16px 32px;
  font-size: 18px;

  @media screen and (max-width: "991px") {
    font-size: 16px;
    padding: 12px 8px;
  }
}

.wrapper {
  padding: 3rem 0;
}

@media screen and (max-width: "991px") {
  .wrapper {
    padding: 2rem 0;
    text-align: center;
  }

  .herosection__title {
    font-size: 34px;
    line-height: 50px;
  }
}

.hire-developer-black-btn {
  padding: 8px 0 9px 25px;
  width: fit-content;
  position: relative;
  border: none; // Remove default button border
  cursor: pointer;
  background: none;
}

.hire-developer-black-btn::after {
  position: absolute;
  content: "";
  width: 42px;
  height: 42px;
  border: 2px solid #ffffff; // Border color
  border-radius: 100px;
  left: 0;
  transition: ease-in-out 0.5s;
}

.hire-developer-black-btn:hover::after {
  width: 110%;
}
</style>
