<template>
  <!-- FOOTER -->
  <footer>
    <div class="footer-bg">
      <div class="">
        <div class="">
          <section class="container-fluid pb-6">
            <div class="row">
              <div
                v-for="(data, index) in mockData.menu"
                :key="index"
                class="col-lg-5th mb-5"
              >
                <h4 class="text-white font-weight-bold mb-3 footer-link-title">
                  {{ data.title }}
                </h4>
                <div class="d-grid gap-2">
                  <a
                    v-for="(detail, index) in data.items"
                    :key="index"
                    :href="detail.link"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-white text-sm cursor-pointer hover-effect-footer footer-link-text"
                  >
                    {{ detail.title }}
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class="container-fluid pb-6 location-section-footer">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-3 text-center mb-4">
                <a class="footer-logo" href="#">
                  <img
                    class="img-fluid"
                    src="/assets/logo2.svg"
                    alt="Mediusware Logo"
                  />
                </a>
                <p class="text-white fs-5">We shape the art of technology</p>
                <div class="d-inline-flex flex-wrap">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/mediusware"
                    class="social-icon"
                  >
                    <img
                      src="/assets/icon/facebook-f-brands.svg"
                      alt="Facebook"
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/mediusware-ltd"
                    class="social-icon"
                  >
                    <img
                      src="/assets/icon/linkedin-in-brands.svg"
                      alt="LinkedIn"
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://twitter.com/MediuswareLtd"
                    class="social-icon"
                  >
                    <img src="/assets/icon/twitter-brands.svg" alt="Twitter" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=zrt7SWJXriY&t=5s"
                    class="social-icon"
                  >
                    <img src="/assets/icon/youtube.svg" alt="YouTube" />
                  </a>
                </div>
              </div>

              <div class="col-lg-9">
                <section class="container-fluid pb-6">
                  <div class="row">
                    <div
                      v-for="(item, index) in mockData.locations"
                      :key="index"
                      class="col-12 col-lg-6 d-flex flex-column flex-md-row gap-2 mb-4"
                    >
                      <!-- Image Section -->
                      <div class="flex-shrink-0 text-center text-md-start">
                        <img
                          :src="item.image"
                          :alt="item.office"
                          class="img-fluid rounded-lg location-img pe-0 pe-md-3 mt-2"
                        />
                      </div>

                      <!-- Text Section -->
                      <div class="d-grid gap-3 content-center mt-3 mt-md-0">
                        <h4 class="text-white text-center text-md-start">
                          {{ item.office }}
                        </h4>
                        <div class="d-flex gap-3 align-items-center">
                          <i
                            class="fas fa-map-marker-alt text-white h-8 w-8"
                          ></i>
                          <p
                            class="font-normal text-sm md:text-base text-white mb-0"
                          >
                            {{ item.address }}
                          </p>
                        </div>
                        <div class="d-flex gap-3 align-items-center">
                          <i class="fas fa-envelope text-white h-8 w-8"></i>
                          <p
                            class="font-normal text-sm md:text-base text-white mb-0"
                          >
                            {{ item.email }}
                          </p>
                        </div>
                        <div class="d-flex gap-3 align-items-center">
                          <i
                            class="fas fa-phone-square-alt text-white h-8 w-8"
                          ></i>
                          <p
                            class="font-normal text-sm md:text-base text-white mb-0"
                          >
                            {{ item.contact }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div class="container-fluid mt-5 mb-3 location-section-footer">
              <div class="row">
                <!-- Loop through a maximum of 10 items -->

                <div class="grid-cols-10">
                  <!-- Slice to show max 10 items -->
                  <div
                    v-for="(award, index) in awards.slice(0, 10)"
                    :key="index"
                    class="d-flex justify-content-center align-items-center bg-white rounded-2 overflow-hidden mb-4"
                  >
                    <img
                      :src="award.image"
                      width="100"
                      height="100"
                      alt=""
                      class="img-fluid mx-auto d-flex justify-content-center p-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- <div class="col-sm-6 col-lg-2">
            <div class="title">Company</div>
            <div class="d-flex flex-column">
              <a target="_blank" href="#" class="service-link">Services</a>
              <a target="_blank" href="#" class="service-link">About us</a>
              <a target="_blank" href="#" class="service-link">FAQs</a>
              <a target="_blank" href="#" class="service-link">Contact</a>
              <a target="_blank" href="#" class="service-link"></a>
              <a target="_blank" href="#" class="service-link"></a>
            </div>
          </div>

          <div class="col-sm-6 col-lg-2">
            <div class="title">Services</div>
            <div class="d-flex flex-column">
              <a
                target="_blank"
                href="https://mediusware.com/software-development/"
                class="service-link"
                >Software Development</a
              >
              <a
                target="_blank"
                href="https://mediusware.com/ui-ux-solution/"
                class="service-link"
                >UI/UX Design & Dev</a
              >

              <a
                target="_blank"
                href="https://mediusware.com/web-development/"
                class="service-link"
                >Web Development</a
              >

              <a
                target="_blank"
                href="https://mediusware.com/service-details/api-integration"
                class="service-link"
                >API Integration</a
              >

              <a
                target="_blank"
                href="https://mediusware.com/services/mobile-app-solution/"
                class="service-link"
                >Mobile App Design</a
              >
              <a
                target="_blank"
                href="https://mediusware.com/ecommerce/"
                class="service-link"
                >E commerce Solutions</a
              >

              <a target="_blank" href="#" class="service-link"></a>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3">
            <div class="title">Bangladesh Address</div>
            <div class="d-flex flex-item align-items-start title-body">
              <img src="/assets/icon/map-marker-alt-solid2.svg" alt="" />
              <p>
                24/1, Taj Mahal Road, Shiya Masjid mor, Floor - 7th & 8th, Ring
                Road, 1207
              </p>
            </div>
            <div class="d-flex flex-item align-items-start title-body">
              <img src="/assets/icon/phone-alt-solid2.svg" alt="" />
              <p>+880 1601 712931</p>
            </div>
            <div class="d-flex flex-item align-items-start title-body">
              <img src="/assets/icon/envelope-regular2.svg" alt="" />
              <p>career@mediusware.com</p>
            </div>
            <div class="d-flex flex-item align-items-start title-body">
              <img src="/assets/icon/clock-regular2.svg" alt="" />
              <p>Monday to Friday: 11 AM - 8 PM</p>
            </div>
          </div>
          <div class="col-sm-6 col-lg-2">
            <div class="title">USA Address</div>
            <div class="d-flex flex-item align-items-start title-body">
              <img src="/assets/icon/map-marker-alt-solid2.svg" alt="" />
              <p>Mt. Pleasant, SC 29466</p>
            </div>
            <div class="d-flex flex-item align-items-start title-body">
              <img src="/assets/icon/phone-alt-solid2.svg" alt="" />
              <p>(843)212-6898</p>
            </div>
            <div class="d-flex flex-item align-items-start title-body">
              <img src="/assets/icon/envelope-regular2.svg" alt="" />
              <p>
                dan@mediusware.io <br />
                kristina@mediusware.io
              </p>
            </div>
            <div class="d-flex flex-item align-items-start title-body">
              <img src="/assets/icon/clock-regular2.svg" alt="" />
              <p>Monday to Friday: <br />11 AM - 8 PM</p>
            </div>
          </div> -->
        </div>
        <div class="copywriter_mw">
          <p class="text-white">
            © {{ currentYear }} Mediusware || All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import mockData from "./../pages/footerdata.json";

export default {
  data() {
    return {
      mockData,
    };
  },
  computed: {
    awards() {
      return this.$store.state.awards;
    },
    location() {
      return this.$store.state.location;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  created() {
    this.$store.dispatch("fetchAwards");
    this.$store.dispatch("fetchOurLocation");
  },
};
</script>

<style scoped lang="scss">
.service-link {
  width: fit-content;
  display: inline;
  position: relative;
  text-decoration: none;
  margin-bottom: 12px !important;
  font-weight: 300;

  &:hover {
    color: #fff !important;
  }

  // after
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    border-radius: 4px;
    background-color: white;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.1s ease-in-out;
  }

  &:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
}

.col-lg-5th {
  width: 20%; /* 1/5th width for 5 items per row */
  padding-left: 3%;
}

@media screen and (max-width: 990px) {
  .col-lg-5th {
    padding-left: 3%;
    width: 30%; /* 1/5th width for 5 items per row */
  }
}
@media screen and (max-width: 756px) {
  .col-lg-5th {
    padding-left: 5%;
    width: 50%; /* 1/5th width for 5 items per row */
  }
}

.location-img {
  width: 220px;
  height: 130px;
}

.grid-cols-10 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default for mobile screens */
  gap: 1rem; /* Adjust spacing */
}

@media (min-width: 768px) {
  /* For tablets and medium screens */
  .grid-cols-10 {
    grid-template-columns: repeat(5, 1fr); /* 5 columns for medium screens */
  }
}
@media (min-width: 1024px) {
  /* For tablets and medium screens */
  .grid-cols-10 {
    grid-template-columns: repeat(7, 1fr); /* 5 columns for medium screens */
  }
}

@media (min-width: 1400px) {
  /* For larger screens (desktop) */
  .grid-cols-10 {
    grid-template-columns: repeat(10, 1fr); /* 10 columns for large screens */
  }
}
.location-section-footer {
  border-top: 1px dashed rgba(164, 170, 173, 0.5);
  padding-top: 50px;
}

.footer-link-title {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}
.footer-link-text {
  font-size: 0.875rem!important;
  line-height: 1.25rem!important;
}
@media (max-width: 700px) {
  .location-section-footer {
    padding-top: 20px;
  }
  .footer-link-title {
    font-size: 1rem !important;
    line-height: 1rem !important;
  }
}

.hover-effect-footer {
  transition: color 0.3s, text-decoration-color 0.3s;
  text-underline-offset: 5px; /* Creates a 5px gap between text and underline */
}

.hover-effect-footer:hover {
  color: rgb(
    34 211 238
  ) !important; /* Adjust as needed for the hover text color */
  text-decoration: underline !important;
  text-decoration-color: rgb(34 211 238) !important; /* Semi-white underline */
  text-decoration-thickness: 2px; /* Adjust thickness as needed */
}
</style>
