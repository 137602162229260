<template>
  <div>
    <div class="mb-3">
      <!-- <h3 class="text-center mb-4">{{ banners?.model_title?.title }}</h3> -->
      <h2 class="text-center mt-5 pt-3 fs-1 mb-2">
        Voices of <span style="color: #00a88e"> Our Team</span>
      </h2>
      <h6 class="description mb-5">
        Personal Journeys That Drive Our Success.
      </h6>

      <!-- <p class="mb-6 text-center">
        {{ banners?.model_title?.sub_title }}
      </p> -->
    </div>

    <div v-if="testimonials.length" class="position-relative">
      <!-- Pass slickSettings to VueSlickCarousel -->
      <div>
        <VueSlickCarousel
          :dots="slickSettings.dots"
          :infinite="slickSettings.infinite"
          :autoplay="slickSettings.autoplay"
          :autoplaySpeed="slickSettings.autoplaySpeed"
          :slidesToShow="slickSettings.slidesToShow"
          :slidesToScroll="slickSettings.slidesToScroll"
          :arrows="slickSettings.arrows"
          :responsive="slickSettings.responsive"
          :speed="slickSettings.speed"
          :cssEase="slickSettings.cssEase"
        >
          <div
            v-for="(item, index) in testimonials"
            :key="index"
            class="px-2 position-relative slide"
          >
            <div class="card shadow-sm p-3 rounded cursor-pointer">
              <div
                class="card-img-top object-fit-contain rounded-2"
                :style="{
                  backgroundImage: `url(${item.thumbnail})`,
                  height: '350px',
                  width: '100%',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }"
                @click="openModal(item.url)"
              ></div>

              <div
                class="card-body d-flex justify-content-between align-items-center"
              >
                <p class="card-text">
                  {{ item.title || "No description available" }}
                </p>
                <!-- <button
                  class="btn btn-primary"
                  @click="
                    openModal(
                      `https://www.youtube.com/watch?v=KzNWlUFGOaU&ab_channel=MediuswareLtd.`
                    )
                  "
                >
                  Play
                </button> -->
                <button
                  class="border border-primary bg-primary text-white rounded-circle d-flex align-items-center justify-content-center"
                  @click="openModal(item.url)"
                >
                  <svg
                    fill="white"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="32"
                  >
                    <path
                      d="M14.752 11.168l-5.197-2.853A1 1 0 008 9.197v5.606a1 1 0 001.555.832l5.197-2.853a1 1 0 000-1.664z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <div class="d-flex justify-content-center mt-2">
          <div class="d-flex gap-1">
            <button
              class="carousel-button border-0 bg-transparent"
              @click="showPrev"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path
                  d="M177.5 414c-8.8 3.8-19 2-26-4.6l-144-136C2.7 268.9 0 262.6 0 256s2.7-12.9 7.5-17.4l144-136c7-6.6 17.2-8.4 26-4.6s14.5 12.5 14.5 22l0 72 288 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-288 0 0 72c0 9.6-5.7 18.2-14.5 22z"
                />
              </svg>
            </button>
            <button
              class="carousel-button border-0 bg-transparent"
              @click="showNext"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path
                  d="M334.5 414c8.8 3.8 19 2 26-4.6l144-136c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22l0 72L32 192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l288 0 0 72c0 9.6 5.7 18.2 14.5 22z"
                />
              </svg>
            </button>
          </div>
        </div>
        <!-- <div>
          <div class="slider-buttons">
            <button @click="prevSlide">Previous</button>
            <button @click="nextSlide">Next</button>
          </div>
        </div> -->
      </div>
      <!-- Modal -->
      <div v-if="modalOpen" class="custom-modal">
        <div class="modal-content" @click.stop>
          <!-- Stop propagation on modal content -->
          <div class="modal-header d-flex justify-content-between">
            <h5 class="modal-title"></h5>
            <button type="button" class="close mb-2 rounded-circle px-2" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body rounded-1">
            <!-- Embed YouTube video using iframe -->
            <div v-if="currentVideo" class="video-wrapper">
              <iframe
                width="100%"
                height="400"
                :src="currentVideo"
                frameborder="0"
                allow="autoplay;"
                allowfullscreen
                class="rounded-1"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="modal-backdrop" @click="closeModal"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { faTruckMedical } from "@fortawesome/free-solid-svg-icons";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "HomeSliderVideo",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      modalOpen: false,
      currentVideo: "",
      allTitles: {
        projects_video_title: {
          title: "Your Video Title",
          sub_title: "Your Subtitle",
        },
      },
      slickSettings: {
        dots: false,
        infinite: true,
        speed: 3000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 556,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },

  computed: {
    testimonials() {
      return this.$store.state.testimonials;
    },
  },
  created() {
    this.$store.dispatch("fetchBanners").then(() => {
      // console.log(this.banners);
    });
  },
  created() {
    this.$store.dispatch("fetchEmployeeTestimonial").then(() => {
      console.log(this.testimonials);
    });
  },

  methods: {
    openModal(url) {
      if (url.includes("youtu.be")) {
        this.currentVideo =
          url.replace("youtu.be/", "www.youtube.com/embed/") + "?autoplay=1";
      } else if (url.includes("youtube.com/watch?v=")) {
        this.currentVideo = url.replace("watch?v=", "embed/") + "?autoplay=1";
      } else {
        this.currentVideo = url;
      }
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      this.currentVideo = null;
    },
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>

<style scoped>
.card-img-top {
  cursor: pointer;
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  position: relative;
  height: 500px;
  z-index: 999999;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.vue-slick-carousel {
  width: 100%;
  height: 600px;
}

.vue-slick-carousel .slick-track {
  display: flex;
}

.vue-slick-carousel .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-wrapper iframe {
  width: 100%;
  height: 100%;
}
.video-wrapper {
  height: 400px;
}
/* Custom styles for arrow buttons */
.slider-buttons {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
}
.carousel-button {
  width: 35px;
  cursor: pointer;
  padding: 5px;
}
.carousel-button svg {
  fill: #002b4f;
}
.card-img-top {
  /* Add any additional styles here */
  width: 100%; /* Ensures the image takes full width */
  border-radius: 0.5rem; /* Optional: round corners */
}
.card-text {
  min-height: 60px;
  margin: auto 0;
}
.description {
  color: #4f4f4f;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  @media screen and (max-width: 1199px) {
    margin-top: 16px;
  }

  a {
    cursor: pointer;
    color: #00a88e;
  }
}
</style>
