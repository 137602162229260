<template>
    <div class="cardContainer" :style="{ borderLeftColor: borderColor }">
        <h1 class="title">{{ title }}</h1>
        <p class="description">{{ description }}</p>
    </div>
</template>

<script>
export default {
    props: ['title', 'description', 'borderColor'],
}

</script>

<style scoped lang="scss">
.cardContainer {
    height: 100%;
    min-height: 229px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #EEF6FD;
    padding: 32px 29px;
    border-radius: 12px;
    border-left: 5px solid;

    .title {
        color: #333;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .description {
        color: #4F4F4F;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 12px;
    }
}
</style>