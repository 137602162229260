<template>
  <div class="contentContainer">
    <h2 class="title">
      {{ titleLeft }} <span class="titleSpanColor">{{ titleRight }}</span>
    </h2>
    <p class="description">
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["titleLeft", "titleRight", "description", "dynamicColor"],
};
</script>

<style scoped lang="scss">
.contentContainer {
  width: 100%;
  max-width: 895px;
  text-align: center;
  padding: 96px 0px 60px;
  margin: 0 auto;

  @media screen and (max-width: 1199px) {
    padding: 30px 0px 40px !important;
  }

  .title {
    color: #333;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.8px;

    @media screen and (max-width: 1199px) {
      font-size: 24px !important;
    }

    span {
      color: #00a88e;
    }
  }

  .description {
    color: #475467;
    font-size: 18px;
    margin-top: 24px;

    @media screen and (max-width: 1199px) {
      margin-top: 16px;
      font-size: 14px;
    }
  }
}
</style>
