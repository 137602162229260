import Vue from "vue";
import VueRouter from "vue-router";
import WebLayout from "../views/webpages/Layout";
// import Job from "../views/webpages/pages/Job";
import JobNew from "../views/webpages/pages/job-new";
// import { compile } from "vue-template-compiler";
import axios from "axios";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: WebLayout,
    children: [
      {
        path: "/",
        component: JobNew,
        name: "Jobs",
        meta: {
          title: "Jobs",
        },
      },
      {
        path: "/jobs",
        component: JobNew,
        name: "Jobs",
        meta: {
          title: "Jobs",
        },
      },
      {
        path: "/login",
        component: () => import("../views/webpages/pages/auth/Login"),
        meta: {
          guest: true,
          title: "Login ",
        },
      },
      {
        path: "/forgot-password",
        component: () => import("../views/webpages/pages/auth/ForgotPassword"),
        meta: {
          guest: true,
          title: "Forget Your Password",
        },
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import("../views/webpages/pages/auth/ResetPassword"),
        meta: {
          guest: true,
          title: "Reset Password",
        },
      },
      {
        path: "job/:slug",
        component: () => import("../views/webpages/pages/JobDetails"),
      },
      {
        path: "job/:slug/apply",
        name: "jobApply",
        component: () => import("../views/webpages/pages/job-apply/index"),
      },
      {
        path: "job/:slug/apply/success",
        name: "jobApplySuccess",
        component: () =>
          import("../views/webpages/pages/job-apply/JobApplySuccess"),
      },
    ],
  },
  {
    path: "/user",
    component: () => import("../views/dashboard/Layout"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "/",
        component: () => import("../views/dashboard/pages/Dashboard"),
        name: "Dashboard",
        meta: {
          title: "Candidate Dashboard",
        },
      },
      {
        path: "/user/application",
        component: () => import("../views/dashboard/pages/Application"),
        name: "jobApplication",
        meta: {
          title: "Job Application",
        },
      },
      {
        path: "/user/exam/progress/:uuid",
        component: () => import("../views/dashboard/pages/ExamProgress"),
        name: "jobProgress",
        meta: {
          title: "Exam Progress",
        },
      },
      {
        path: "/user/examination",
        component: () => import("../views/dashboard/pages/Examination"),
        name: "Examination",
        meta: {
          title: "Examination",
        },
      },
      {
        path: "/user/settings",
        component: () => import("../views/dashboard/pages/Settings"),
        name: "Settings",
        meta: {
          title: "Settings",
        },
      },
      {
        path: "/user/help",
        component: () => import("../views/dashboard/pages/Help"),
        name: "Help",
      },
    ],
  },
  {
    path: "/exam",
    component: () => import("../views/exam/Layout"),
    children: [
      {
        path: ":uuid",
        component: () => import("../views/exam/Exam"),
        name: "startExam",
        meta: {
          title: "Assessment",
        },
      },
      {
        path: ":uuid/finish",
        component: () => import("../views/exam/ExamFinished"),
        name: "examFinish",
        meta: {
          title: "Exam Finish",
        },
      },
    ],
  },
  {
    path: "*",
    component: () => import("../views/errors/NotFound"),
    meta: {
      title: "Page not found",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.matched.some((record) => record.meta.auth)) {
    if (store.state.token == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      // check current token
      axios
        .get("/candidate/", {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        })
        .then((res) => {
          store.commit("UPDATE_USER", res.data);
          next();
        })
        .catch((err) => {
          store.commit("SET_TOKEN", "");
          store.commit("UPDATE_USER", "");
          console.log(err);
          next({ name: "Login" });
        });
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    // console.log(!store.state.token)
    if (!store.state.token) {
      next();
    } else {
      next({ name: "Home" });
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title;
  });
});

export default router;
