<template>
  <div class="contentContainer">
    <img
      :src="'../../../assets/applicationProcess/process' + imageid + '.svg'"
      :alt="title_pro"
      class="images-process"
    />

    <div>
      <h1 class="title">{{ title_pro }}</h1>
      <p class="description">{{ description_pro }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["imageid", "title_pro", "description_pro"],
};
</script>

<style scoped lang="scss">
.contentContainer {
  .title {
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 40px;
    margin-bottom: 8px;

    @media (max-width: 1199px) {
      font-size: 18px;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }

  .description {
    color: #4f4f4f;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;

    @media screen and (max-width: 1199px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  // Responsive styles for smaller screens
  @media (max-width: 1199px) {
    width: 100%;
    display: flex;

    .images-process {
      height: 60px;
      width: 60px;
      margin-right: 1rem;
    }
  }
}
</style>
