import "bootstrap";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

// import Carousel3d from "vue-carousel-3d";
import VCalendar from "v-calendar";

import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
Vue.use(LottieVuePlayer);
Vue.use(ToastPlugin);

Vue.use(VCalendar, {
  componentPrefix: "v",
});

// Vue.use(Carousel3d);

import "./scss/main.scss";

Vue.config.productionTip = false;

import VeeValidate from "vee-validate";

Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});

import Prism from "prismjs";
import "prismjs/components/prism-json";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-scss";
import "prismjs/components/prism-python";
import "prismjs/components/prism-markup-templating";
import "prismjs/components/prism-php";
import "prismjs/themes/prism.css";

Vue.directive("highlight", {
  inserted: (el) => {
    setTimeout(() => {
      Prism.highlightAll();
    }, 1000);
  },
});

import VueProgressBar from "vue-progressbar";

const options = {
  color: "#0060af",
  failedColor: "#f20000",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

Vue.use(VueProgressBar, options);

// import {axios as instance} from "./axios";
import axios from "axios";

import "./registerServiceWorker";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("_token");

export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
